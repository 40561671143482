





















import Vue from 'vue'
export default Vue.extend({
  data: () => ({
    navOpenArticles: false,
    navOpenLiveReporting: false,
    navOpenSettings: false,
    navOpenUsers: false,
  }),
  computed: {
    isLiveReportingActive() {
      return this.$route.path.includes('series-create') || this.$route.path.includes('tournament')
    },
    isSettingsActive() {
      return this.$route.path.includes('settings')
    },
    isUsersActive() {
      return this.$route.path.includes('users')
    },
  },
  methods: {
    toggleSubNavLiveReporting() {
      this.navOpenLiveReporting = !this.navOpenLiveReporting
    },
    toggleSubNavArticles() {
      this.navOpenArticles = !this.navOpenArticles
    },
    toggleSubNavSettings() {
      this.navOpenSettings = !this.navOpenSettings
    },
    toggleSubNavUsers() {
      this.navOpenUsers = !this.navOpenUsers
    },
  },
})
