import { ActionContext, ActionTree } from 'vuex'
import axios from '../../services/axios'
import { RootState } from '../types'
import { FacebookState } from './types'
import { Channel } from '~/models/facebook/channel'

interface FacebookActionContext extends ActionContext<FacebookState, RootState> {}

export const actions: ActionTree<FacebookState, RootState> = {
  async fetchAndSetChannels(
    { commit, rootGetters }: FacebookActionContext,
  ) {
    commit('setLoading', true)
    try {
      const { data } = await axios.get(
        `/settings?sort[0]=created_at&sortby[0]=desc`
      )
      commit('setChannelsList', data.data)
      commit('setLoading', false)
    } catch (e) {
      commit('setLoading', false)
      console.log('fetchAndSetPage Error: ', e)
      commit('setError', e.response.data.Message, { root: true })
      throw e
    }
  },
  async fetchChannelByName(
    { commit, rootGetters }: FacebookActionContext,
    payload: { name: string }
  ) {
    commit('setDataLoading', true, { root: true })
    try {
      const { data } = await axios.get(`/settings/${payload.name}`)
      commit('setCurrentChannel', data.data)
      commit('setDataLoading', false, { root: true })
    } catch (e) {
      console.log('fetchChannelById Error: ', e)
      commit('setDataLoading', false, { root: true })
      commit('setError', e.response.data.Message, { root: true })
      throw e
    }
  },
  createChannel: async (
    { rootGetters, commit }: ActionContext<FacebookState, RootState>,
    payload: { channel: any }
  ) => {
    commit('setDataLoading', true, { root: true })
    try {
      const { data } = await axios.post(`/settings`, payload.channel)
      commit('setDataLoading', false, { root: true })
      return data
    } catch (e) {
      console.log('createChannel Error: ', e)
      commit('setError', e.response.data.Message, { root: true })
      commit('setDataLoading', false, { root: true })
      throw e
    }
  },
  updateChannel: async (
    { rootGetters, commit }: ActionContext<FacebookState, RootState>,
    payload: { channel: any }
  ) => {
    commit('setDataLoading', true, { root: true })
    try {
      await axios.post(`/settings/`, payload.channel)
      commit('setDataLoading', false, { root: true })
    } catch (e) {
      console.log('updateChannel Error: ', e)
      commit('setDataLoading', false, { root: true })
      commit('setError', e.response.data.Message, { root: true })

      throw e
    }
  },
  deleteChannel: async (
    { rootGetters, commit }: ActionContext<FacebookState, RootState>,
    payload: { channel: Channel }
  ) => {
    commit('setDataLoading', true, { root: true })
    try {
      await axios.delete(`/settings/` + payload.channel.id)
      commit('setDataLoading', false, { root: true })
    } catch (e) {
      console.log('deleteChannel Error: ', e)
      commit('setDataLoading', false, { root: true })
      commit('setError', e.response.data.Message, { root: true })
      throw e
    }
  },
  async fetchAndSetRevenueList(
    { commit, rootGetters }: FacebookActionContext,
  ) {
    commit('setDataLoading', true, { root: true })
    try {
      const { data } = await axios.get(`/revenues/last/10`)
      commit('setRevenueList', data.data)
      commit('setDataLoading', false, { root: true })
    } catch (e) {
      console.log('fetchAndSetRevenueList Error: ', e)
      commit('setDataLoading', false, { root: true })
      commit('setError', e.response.data.Message, { root: true })
      throw e
    }
  },
}

export default actions
