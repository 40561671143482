



































import Vue from 'vue'
import { mapActions, mapState } from 'vuex'
import SearchBar from '@/components/global/SearchBar.vue'
import moment from 'moment'

export default Vue.extend({
  components: {
    SearchBar,
  },
  computed: {
    ...mapState({
      revenueList: (state: any) => state.facebook.revenueList,
      loading: (state: any) => state.facebook.loading,
    }),
    listTotal() {
      return this.revenueList && this.revenueList.length
    },
  },
  methods: {
    ...mapActions({
      refreshTableData: 'facebook/fetchAndSetRevenueList',
    }),
    revenueDateFormatter(row){
      return !row.revenue_date ? '-' : moment(row.revenue_date).format('MM-DD-YYYY HH:mm')
    }
  },
  mounted(){
    this.refreshTableData()
  }
})
