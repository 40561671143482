













import Vue from 'vue'
import { mapState } from 'vuex'
import Multiselect from 'vue-multiselect'
import DeleteConfimationButton from '@/components/global/DeleteConfirmationButton.vue'
import ChannelEdit from '@/components/facebook/FacebookEdit.vue'

export default Vue.extend({
  components: {
    DeleteConfimationButton,
    Multiselect,
    ChannelEdit,
  },
  data() {
    return {
      loading: false,
    }
  },
  computed: {
    ...mapState({
      currentChannel: (state: any) => state.facebook.currentChannel || {},
    }),
    name() {
      return this.$route.params.name
    },
  },
  watch: {},
  async mounted() {
    await this.$store.dispatch('facebook/fetchChannelByName', { name: this.name })
  },
  methods: {
    updateChanged() {},
    async deleteChannel(data) {
      try {
        this.loading = true
        await this.$store.dispatch('facebook/deleteChannel', { channel: data })
        // this.$router.push('/facebook/list')
        this.$notify({
          group: 'messages',
          title: 'success',
          message: 'successfully deleted',
          type: 'info',
        })
        this.$router.push('/facebook/list')
      } catch (error) {
        this.$notify({
          title: 'Error',
          message: this.$store.state.error,
          type: 'error',
          duration: 5000,
        })
      }
      this.loading = false
    },
    async updateChannel(data) {
      try {
        this.loading = true
        await this.$store.dispatch('facebook/updateChannel', data)
        this.$router.push('/facebook/list')
        this.$notify({
          group: 'messages',
          title: 'success',
          message: 'successfully updated',
          type: 'info',
        })
      } catch (error) {
        this.$notify({
          title: 'Error',
          message: this.$store.state.error,
          type: 'error',
          duration: 5000,
        })
      }
      this.loading = false
    },
  },
})
