import Vue from 'vue'
import Vuex, { StoreOptions } from 'vuex'
import { RootState } from './types'
import { auth } from './auth'
import { facebook } from './facebook'

Vue.use(Vuex)

export const state = (): RootState => ({ error: null, loading: false })

const store: StoreOptions<RootState> = {
  state: {
    error: null,
    loading: false,
  },
  mutations: {
    setError(state, message) {
      state.error = message
    },
    setDataLoading(state, payload) {
      state.loading = payload
    },
  },
  modules: {
    auth,
    facebook,
  },
}

export default new Vuex.Store<RootState>(store)
