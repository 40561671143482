<template lang="">
  <div class="search-bar">
    <div>
      <span v-for="(filter, index) in myFilters" :key="index">
        <span v-if="filter.type == 'input'">
          <el-input
            size="small"
            v-model="myFilterInput[filter.prop]"
            :placeholder="`Search ${filter.label}`"
            prefix-icon="el-icon-search"
            clearable
            style="width: 160px"
            @change="handleSearch"
            v-if="currentProp == filter.prop"
          />
        </span>
        <span v-if="filter.type == 'select'">
          <el-select
            v-model="myFilterInput[filter.prop]"
            filterable
            :remote="!!filter.optionSearch"
            :remote-method="filter.optionSearch ? filter.optionSearch : () => {}"
            :loading="loading"
            size="small"
            :placeholder="`Select ${filter.label}`"
            @change="handleSearch"
            v-if="currentProp == filter.prop"
          >
            <el-option
              v-for="(item, index) in filter.options"
              :key="index"
              :label="item"
              :value="item"
            />
          </el-select>
        </span>
        <span v-if="filter.type == 'dateRange'">
          <el-date-picker
            v-model="myFilterInput[filter.prop]"
            type="daterange"
            start-placeholder="Start Date"
            end-placeholder="End Date"
            :default-time="['00:00:00']"
            style="height:32px;"
            @change="handleSearch"
            v-if="currentProp == filter.prop"
          >
          </el-date-picker>
        </span>
      </span>
    </div>
    <el-radio-group v-model="currentProp" @change="handleSearch">
      <el-radio-button
        v-for="(filter, index) in myFilters"
        :key="index"
        :label="filter.prop"
        required
      >
        <i :class="filter.icon"></i
      ></el-radio-button>
    </el-radio-group>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex'
import Multiselect from 'vue-multiselect'

export default {
  components: {
    Multiselect,
  },
  props: {
    value: {
      type: Object,
      default: () => {},
    },
    filterOptions: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      myFilterInput: [],
      currentProp: '',
    }
  },
  computed: {
    categories() {
      return Array.isArray(this.categoryData) ? [] : []
    },
    defaultFilters() {
      return [
        {
          prop: 'filter_series_id',
          type: 'input',
          label: 'Series ID',
          icon: 'el-icon-trophy',
          value: '',
        },
        {
          prop: 'filter_event_id',
          type: 'input',
          label: 'Event ID',
          icon: 'el-icon-alarm-clock',
          value: '',
        },
        {
          prop: 'id',
          type: 'input',
          label: 'ID',
          icon: 'fas fa-fingerprint',
          value: '',
        },
        {
          prop: 'content',
          type: 'input',
          label: 'Content',
          icon: 'fas fa-file',
          value: '',
        },
        {
          prop: 'dateRange',
          type: 'dateRange',
          label: 'Date Range',
          icon: 'fas fa-calendar',
          value: [],
        },
        {
          prop: 'category',
          type: 'select',
          label: 'Category',
          icon: 'fas fa-sitemap',
          options: ['All', ...this.categories],
          optionSearch: this.categorySearch,
          value: 'All',
        },
        {
          prop: 'lr_type',
          type: 'select',
          label: 'Live Reporting Type',
          icon: 'fas fa-layer-group',
          options: ['series', 'event', 'article'],
          value: 'series',
        },
      ]
    },
    allFilters() {
      return [
        ...this.defaultFilters.filter(
          defaultFilter => !this.filterOptions.find(filter => defaultFilter.prop == filter.prop)
        ),
        ...this.filterOptions.map(filter => {
          const defaultValue = this.defaultFilters.find(
            defaultFilter => defaultFilter.prop == filter.prop
          )
          return defaultValue
            ? { ...defaultValue, ...filter }
            : {
                ...{
                  type: 'input',
                  icon: 'fas fa-heading',
                  value: '',
                },
                ...filter,
              }
        }),
      ]
    },
    myFilters() {
      return Object.keys(this.value).map(key => {
        const myFilter = this.allFilters.find(filter => filter.prop == key)
        return myFilter
          ? myFilter
          : {
              prop: key,
              type: 'input',
              label: key.charAt(0).toUpperCase() + key.slice(1),
              icon: 'fas fa-heading',
              value: '',
            }
      })
    },
  },
  watch: {
    value: {
      handler() {
        this.loadData()
      },
      deep: true,
    },
  },
  async mounted() {
    this.loadData()
  },
  methods: {
    ...mapActions({
      fetchCategories: 'categories/fetchAndSetCategories',
    }),
    async loadData() {
      this.myFilterInput = { ...this.value }
      this.currentProp = this.currentProp
        ? this.currentProp
        : Object.keys(this.myFilterInput).length > 1
        ? Object.keys(this.myFilterInput)[1]
        : Object.keys(this.myFilterInput).length
        ? Object.keys(this.myFilterInput)[0]
        : ''
      if (this.value.categories) {
        const payload = { pageOffset: 0, pageSize: 20 }
        await this.fetchCategories(payload)
      }
      Object.keys(this.myFilterInput).forEach(key => {
        const myFilter = this.myFilters.find(filter => filter.prop == key)
        this.myFilterInput[key] = this.myFilterInput[key]
          ? this.myFilterInput[key]
          : myFilter
          ? myFilter.value
          : ''
      })
    },
    async categorySearch() {
      const payload = { pageOffset: 0, pageSize: 20, query: `name=${key}` }
      await this.fetchCategories(payload)
    },
    changeFilter(filter) {
      this.currentProp = filter.prop
    },
    changeQuery() {
      this.$emit('input', this.myFilterInput)
    },
    handleSearch() {
      this.$emit('input', this.myFilterInput)
      this.$emit('search', { prop: this.currentProp, value: this.myFilterInput[this.currentProp] })
    },
  },
}
</script>
<style lang="scss" scoped>
.multiselect {
  margin-top: 0;
}
.search-bar {
  display: flex;
  gap: 2rem;
  align-items: center;
  margin-bottom: 1.5rem;
  margin-right: 3rem;
}
</style>
