


















































import Vue from 'vue'
import { mapActions, mapState } from 'vuex'

export default Vue.extend({
  computed: {
    ...mapState({
      channelsData: (state: any) => state.facebook.channelsData,
      loading: (state: any) => state.facebook.loading,
    }),
    listTotal() {
      return this.channelsData && this.channelsData.length
    },
  },
  methods: {
    ...mapActions({
      refreshTableData: 'facebook/fetchAndSetChannels',
    }),
  },
  mounted() {
    this.refreshTableData()
  }
})
