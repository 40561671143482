export class Channel {
  id: number
  page_name: string
  page_id: string
  token?: string

  public constructor({ id, page_name, page_id, token }) {
    this.id = id
    this.page_name = page_name
    this.page_id = page_id
    this.token = token
  }
}
