import { MutationTree } from 'vuex'
import { FacebookState } from './types'
import { Channel } from '@/models/facebook/channel'

export const mutations: MutationTree<FacebookState> = {
  setLoading(state, payload) {
    state.loading = payload
  },
  setChannelsList(state, data) {
    state.channelsData = []
    const tmpChannels: Channel[] = []
    for (let i = 0; data && i < data.length; i++) {
      const prop = data[i]
      tmpChannels.push(new Channel(prop))
    }
    state.channelsData = tmpChannels
  },
  setCurrentChannel(state, prop) {
    state.currentChannel = new Channel(prop)
  },
  setChannelMeta(state, value) {
    state.channelsMeta = value
  },
  setRevenueList(state, payload) {
    state.revenueList = payload
  },
}
export default mutations
