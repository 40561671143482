






import Vue from 'vue'
import Multiselect from 'vue-multiselect'
import { Channel } from '../../models/facebook/channel'
import ChannelEdit from '../../components/facebook/FacebookEdit.vue'

export default Vue.extend({
  components: {
    Multiselect,
    ChannelEdit,
  },
  data() {
    return {
      loading: false,
      channel: {},
    } as {
      loading: boolean
      channel: Channel
    }
  },
  computed: {},
  mounted() {},
  methods: {
    async saveChannel(data) {
      try {
        this.loading = true
        await this.$store.dispatch('facebook/createChannel', data)
        this.$router.push('/facebook/list')
        this.$notify({
          group: 'messages',
          title: 'success',
          message: 'successfully saved',
          type: 'info',
        })
      } catch (error) {
        this.$notify({
          title: 'Error',
          message: this.$store.state.error,
          type: 'error',
          duration: 5000,
        })
      }
      this.loading = false
    },
  },
})
