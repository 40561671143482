var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"inner"},[(_vm.isEditMode)?_c('h2',[_vm._v("Edit Channel: "+_vm._s(_vm.editData.name))]):_c('h2',[_vm._v("Create a Channel")]),_c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('el-form',{staticClass:"edit-category-form",attrs:{"model":_vm.editData},nativeOn:{"submit":function($event){$event.preventDefault();}}},[_c('el-form-item',{attrs:{"label":"Page Name"}},[_c('ValidationProvider',{staticClass:"error",attrs:{"tag":"div","rules":"required","name":"Name"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-input',{staticClass:"input",attrs:{"id":"name-input","name":"name-input","type":"name","placeholder":"Page Name","required":""},model:{value:(_vm.editData.page_name),callback:function ($$v) {_vm.$set(_vm.editData, "page_name", $$v)},expression:"editData.page_name"}}),_c('span',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('el-form-item',{attrs:{"label":"Page ID"}},[_c('ValidationProvider',{staticClass:"error",attrs:{"tag":"div","rules":"required","name":"Page ID"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-input',{staticClass:"input",attrs:{"id":"name-input","name":"name-input","type":"name","placeholder":"Page ID","required":""},model:{value:(_vm.editData.page_id),callback:function ($$v) {_vm.$set(_vm.editData, "page_id", $$v)},expression:"editData.page_id"}}),_c('span',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('el-form-item',{attrs:{"label":"Token"}},[_c('ValidationProvider',{staticClass:"error",attrs:{"tag":"div","rules":"required","name":"Token"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('el-input',{staticClass:"input",attrs:{"id":"name-input","name":"name-input","type":"name","placeholder":"Token","required":""},model:{value:(_vm.editData.token),callback:function ($$v) {_vm.$set(_vm.editData, "token", $$v)},expression:"editData.token"}}),_c('span',[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('el-button',{attrs:{"type.native":"saveChannel","type":"success","icon":"el-icon-check","loading":_vm.loading},on:{"click":function($event){return handleSubmit(_vm.saveChannel)}}},[_vm._v(" Save ")])],1)]}}])}),(_vm.isEditMode)?_c('delete-confimation-button',{attrs:{"label-text":'Delete Channel',"checkbox-text":'I would like to delete this channel',"help-text":'Clicking the delete button will permanently delete this channel.',"button-text":'Delete'},on:{"deleteContent":_vm.deleteChannel}}):_vm._e()],1)])}
var staticRenderFns = []

export { render, staticRenderFns }