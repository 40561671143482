<template>
  <div class="login-wrapper">
    <div class="login-content">
      <h2 class="login-title">Please login to the dashboard</h2>

      <form ref="loginForm" class="login-form" action="/login" @submit.prevent="login">
        <div class="form-row">
          <div class="form-field">
            <label for="emailInput">Email</label>
            <input
              id="emailInput"
              v-model="email"
              name="email"
              type="text"
              autocomplete="username"
              placeholder="example@gxm.com"
              required
            />
          </div>
        </div>

        <div class="form-row">
          <div class="form-field">
            <label for="passwordInput">Password</label>
            <input
              id="passwordInput"
              v-model="password"
              name="password"
              type="password"
              autocomplete="current-password"
              placeholder="********"
              required
            />
          </div>
        </div>
        <el-button type="primary" icon="el-icon-key" native-type="submit" :loading="loading">
          Login
        </el-button>
      </form>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'

export default Vue.extend({
  name: 'Login',
  data() {
    return {
      loading: false,
      email: '',
      password: '',
    }
  },
  computed: {},
  methods: {
    async login() {
      try {
        this.loading = true
        // await this.$store.dispatch('auth/login', {
        //   data: {
        //     username: this.email,
        //     password: this.password,
        //   },
        // })
        if (this.email === 'admin' && this.password === 'BlazeTV###') {
          this.$store.dispatch('auth/authenticateUser')
          this.$router.push('/')
        } else {
          this.$notify({
            group: 'messages',
            title: 'Login Failed',
            message: 'Invalid login credentails',
            type: 'info',
          })
        }
        this.loading = false
      } catch (error) {
        this.loading = false
        if (error.response === undefined) {
          // Network errors do not return a status message
          this.$notify({
            group: 'messages',
            title: 'Network error logging in',
            message: error,
            type: 'error',
          })
        } else if (error.response.status === 401) {
          // an authentication error
          this.$notify({
            group: 'messages',
            title: 'Login Failed',
            message: error.response.data.Message,
            type: 'info',
          })
        } else {
          this.$notify({
            group: 'messages',
            title: 'Error logging in',
            message: error,
            type: 'error',
          })
        }
        this.loginError = true
      }
    },
  },
})
</script>

<style lang="scss" scoped>
.login-wrapper {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .login-content {
    max-width: 1000px;
    .login-title {
      text-transform: none;
    }

    .login-form {
      padding: 25px;
      border: 1px solid rgba($black, 0.6);
      border-radius: 5px;
    }
  }
}

.button {
  margin-top: 15px;
}

.password-reset-row {
  margin-bottom: 10px;
}
</style>
