import { FacebookState } from './types'

export const initState = (): FacebookState => ({
  loading: false,
  channelsData: [],
  channelsMeta: null,
  currentChannel: { id: 4 },
  revenueList: [],
})

export default initState
