<template>
  <div>
    Loading...
  </div>
</template>

<script type="ts">
import Vue from 'vue'

export default Vue.extend({
  computed: {
  },
  async created() {
    this.$router.push('/facebook/list')
  }
})
</script>

<style lang="scss" scoped>
.inner {
  text-align: center;
}
</style>
